import {GdprResponseModel} from "./gdpr-response.model";

export class PostGdprResponseModel {
  token: string;
  gdprResponse: GdprResponseModel;

  constructor(token: string, gdprResponse: GdprResponseModel) {
    this.token = token;
    this.gdprResponse = gdprResponse;
  }
}
