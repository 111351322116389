import {Routes} from '@angular/router';
import {LayoutComponent} from "./pages/layout/layout.component";
import {AutoLoginPartialRoutesGuard} from "angular-auth-oidc-client";
import {CallbackComponent} from "./auth/callback/callback.component";
import {GdprConsentFormComponent} from "./pages/public/gdpr-consent-form/gdpr-consent-form.component";

export const routes: Routes = [
  {
    path: 'gdpr-consent-form',
    component: GdprConsentFormComponent
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
    children: [
      {
        path: '',
        redirectTo: '/contacts/all',
        pathMatch: "full"
      },
      {
        path: 'contacts',
        children: [
          {
            path: 'all',
            loadComponent: () => import('./pages/contacts/contacts-list/contacts-list.component').then(c => c.ContactsListComponent)
          },
          {
            path: 'lists',
            loadComponent: () => import('./pages/contacts/my-contacts-list/my-contacts-list.component').then(c => c.MyContactsListComponent)
          },
          {
            path: 'review',
            loadComponent: () => import('./pages/contacts/review-contacts/review-contacts.component').then(c => c.ReviewContactsComponent)
          }
        ]
      },
      {
        path: 'meetings',
        loadComponent: () => import('./pages/meetings/meetings.component').then(c => c.MeetingsComponent),
      },
      {
        path: 'companies',
        loadComponent: () => import('./pages/companies/companies.component').then(c => c.CompaniesComponent),
      },
      {
        path: 'groups',
        loadComponent: () => import('./pages/groups/groups.component').then(c => c.GroupsComponent),
      },
      {
        path: 'administration',
        loadComponent: () => import('./pages/administration/administration.component').then(c => c.AdministrationComponent)
      }
    ]
  },
  {
    path: 'callback',
    component: CallbackComponent,
  },
  {
    path: '**',
    redirectTo: '/contacts/all',
  }
];
