import { Component } from '@angular/core';

@Component({
  selector: 'crm-callback',
  standalone: true,
  imports: [],
  templateUrl: './callback.component.html',
  styleUrl: './callback.component.scss'
})
export class CallbackComponent {

}
