import {Component} from '@angular/core';
import {AsyncPipe, JsonPipe, NgClass, NgIf, NgOptimizedImage} from "@angular/common";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {OidcSecurityService} from "angular-auth-oidc-client";
import {InlineSVGModule} from "ng-inline-svg-2";
import {TooltipModule} from "primeng/tooltip";
import {AuthService} from "../../../../auth/auth.service";
import {Observable} from "rxjs";
import {User} from "../../../../shared/models/user.model";

@Component({
  selector: 'crm-sidebar',
  standalone: true,
  imports: [
    NgOptimizedImage,
    RouterLink,
    RouterLinkActive,
    NgClass,
    AsyncPipe,
    JsonPipe,
    InlineSVGModule,
    TooltipModule,
    NgIf
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent {

  currentUser$: Observable<User>;

  constructor(private oidcSecurityService: OidcSecurityService, authService: AuthService) {
    this.currentUser$ = authService.currentUser;
  }

// This function is used to toggle a sublist of links in the sidebar
  toggleSublist(sublist: HTMLElement, arrowIcon: HTMLElement) {
    sublist.classList.toggle('hideSubList');
    arrowIcon.classList.toggle('-rotate-180');
  }

  logout() {
    this.oidcSecurityService.logoffAndRevokeTokens().subscribe(() => {
      location.reload();
    });
  }
}
